@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@600&display=swap');

/* General Resets */
body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
}

/* Basic Styling */
body {
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
}

/* Container */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Services Page */
.services-page {
  padding: 60px 10px;
  background-color: #f4f4f4;
}

.services-page h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #007BFF;
  text-align: center;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.service-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  width: calc(33% - 20px);
  display: flex;
  flex-direction: column;
  text-align: left;
  min-height: 400px; /* Ensures consistent card height */
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.service-image {
    width: 100%;
    height: 200px; /* Adjust as needed */
    object-fit: contain; /* Or cover */
    border-radius: 12px;
    margin-bottom: 15px;
  }
  

.service-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensures content starts at the top */
  flex-grow: 1; /* Ensures content takes up remaining space */
}

.service-content h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  color: #007BFF;
  margin-bottom: 10px;
}

.service-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #666;
}

.service-content ul {
  list-style-type: disc;
  padding-left: 20px;
  flex-grow: 1; /* Ensures ul takes up remaining space */
}

.service-content ul li {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #333;
}

.service-content:last-child {
  margin-bottom: 0; /* Ensures last element has no bottom margin */
}

/* Responsive Styling */
@media (max-width: 768px) {
    .services-grid {
      flex-direction: column;
      align-items: center;
    }
  
    .service-card {
      width: 100%;
      margin-bottom: 20px; /* Add spacing between cards */
    }
  
    .services-page h2 {
      font-size: 2rem;
    }
  
    .service-content h3 {
      font-size: 1.5rem;
    }
  
    .service-content p, 
    .service-content ul li {
      font-size: 1rem;
    }
  
    .service-image {
      height: auto; /* Adjust height for a better fit */
      object-fit: cover;
    }
  
    .service-content {
      padding: 15px;
    }
  
    .container {
      padding: 10px; /* Reduce padding for smaller screens */
    }
  
    .services-page {
      padding: 40px 10px;
    }
  }
  