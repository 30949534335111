/* General Resets */
body, h1, h2, h3, p, a, button {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
}

/* Basic Styling */
body {
    line-height: 1.6;
    color: #333;
    background-color: #f4f4f4;
}

a {
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s ease;
}

a:hover, .nav-link.active {
    color: #ffc107;
}

button {
    cursor: pointer;
    outline: none;
    font-family: inherit;
}

/* Header */
.header {
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 0px 20px; /* Reduce padding to decrease margins */
    position: sticky;
    top: 0;
    z-index: 1000;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Logo Container */
.logo-container {
    display: flex;
    align-items: center;
    text-decoration: none; /* Ensure no underline */
}

.logo-image {
    height: 150px; /* Adjust height to ensure it's visible and fits well */
    margin-right: 10px; /* Adjust the spacing as needed */
}

.logo-text {
    font-size: 1.5rem; /* Adjust the font size as needed */
    font-family: 'Montserrat', sans-serif; /* Ensure it matches your theme */
    color: #000; /* Adjust the color as needed */
}

.navigation {
    display: flex;
    align-items: center;
}

.navigation a {
    margin-left: 10px; /* Reduce the margin between navigation items */
    font-size: 1rem;
    color: #333;
    transition: color 0.3s ease;
}

.navigation a:hover, .navigation a.active {
    color: #ffc107;
}
/* Hero Section Enhancements */
.hero {
    background: linear-gradient(rgba(0, 123, 255, 0.6), rgba(0, 123, 255, 0.6)), url('../public/hero.png') no-repeat center center / cover;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
}

.hero-content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
}

.hero h2 {
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.hero p {
    color: #ffffff;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.cta-button {
    font-size: 1rem;
    padding: 12px 20px;
    background-color: #ffc107;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s;
    border: none;
    color: #333;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.cta-button:hover {
    background-color: #d2c396;
    transform: scale(1.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.services .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .services-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    margin: 10px 0;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 12px;
    transition: transform 0.3s, box-shadow 0.3s;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
  
  .service-image {
    width: 100%;
    height: auto;
    max-height: 200px; /* Adjust to fit within the card without zooming too much */
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 15px;
  }
  
  
  .service-info {
    text-align: center;
  }
  
  .service-info h4 {
    color: #007BFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    margin: 5px 0;
  }
  
  .service-info p {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 1.4;
  }

/* Reviews Section */
.reviews {
    padding: 60px 10px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 40px 0;
}

.reviews h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    margin-bottom: 30px;
    color: #007BFF;
}

.reviews-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.review-card {
    width: 48%;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 12px;
    margin: 10px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.review-card p {
    margin: 5px 0;
}

.review-link {
    display: block;
    margin-top: 10px;
    font-size: 0.9rem;
    color: #007BFF;
}

.review-stars {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.star-icon {
    color: #ffc107;
    margin-right: 5px;
}

/* About Section */
.about {
    padding: 20px 10px;
    text-align: center;
    background-color: #f4f4f4;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.about h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #007BFF;
}

.about p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
}


/* Footer */
.footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px 10px;
}

.footer p {
    margin: 0px 0;
}

.footer a {
    color: #ffc107;
    text-decoration: none;
    margin: 0 5px;
}

.footer a:hover {
    text-decoration: underline;
}
@media (max-width: 768px) {
    .service-card, .review-card {
        width: 100%;
    }

    .services-row {
        flex-direction: column;
    }

    .hero {
        flex-direction: column;
        padding: 20px;
    }

    .hero-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .hero-image {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .hero-text {
        max-width: 100%;
    }

    .hero h2, .hero p {
        font-size: calc(1.5em + 1vw);
    }

    .about {
        padding: 20px 10px;
        margin: 20px 0;
    }

    .about h3 {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .about p {
        font-size: 1rem;
        line-height: 1.4;
    }

    .reviews {
        padding: 40px 10px;
        margin: 20px 0;
    }

    .reviews h3 {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .reviews-container {
        flex-direction: column;
        align-items: center;
    }

    .review-card {
        margin: 10px 0;
    }

    .footer {
        padding: 15px 10px;
    }

    .footer p {
        font-size: 0.9rem;
    }

    .footer a {
        font-size: 0.9rem;
    }

    .contact {
        padding: 30px 10px;
        margin: 20px 0;
    }

    .contact h3 {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .contact-form {
        padding: 20px;
        margin: 0 10px;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .form-group label {
        margin-bottom: 3px;
    }

    .form-control {
        padding: 10px;
    }

    .cta-button {
        font-size: 0.9rem;
        padding: 10px 15px;
    }

    .header {
        padding: 10px 20px;
    }

    .header-content {
        flex-direction: column;
    }

    .logo-container {
        margin-bottom: 20px;
    }

    .logo-image {
        height: 100px;
    }

    .logo-text {
        font-size: 1.2rem;
    }

    .navigation {
        flex-direction: column;
    }

    .navigation a {
        margin: 5px 0;
    }

    .hero {
        height: auto;
        padding: 20px;
    }

    .hero h2 {
        font-size: 2rem;
    }

    .hero p {
        font-size: 1rem;
    }

    .cta-button {
        font-size: 0.8rem;
        padding: 10px 15px;
    }

    .services .container {
        padding: 0;
    }

    .services-row {
        flex-direction: column;
        align-items: center;
    }

    .service-card {
        width: 100%;
        margin: 10px 0;
    }

    .service-image {
        width: 100%;
    }

    .reviews-container {
        flex-direction: column;
        align-items: center;
    }

    .review-card {
        width: 100%;
        margin: 10px 0;
    }
}