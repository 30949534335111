/* Contact Section */
.contact {
    padding: 60px 10px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 40px 0;
}

.contact h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    margin-bottom: 30px;
    color: #007BFF;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.form-control {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.cta-button {
    font-size: 1rem;
    padding: 12px 20px;
    background-color: #ffc107;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s;
    border: none;
    color: #333;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.cta-button:hover {
    background-color: #d2c396;
    transform: scale(1.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.success-message {
    color: green;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
}
@media (max-width: 768px) {
    .contact {
        padding: 30px 10px;
        margin: 20px 0;
    }

    .contact h3 {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .contact-form {
        padding: 20px;
        margin: 0 10px;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .form-group label {
        margin-bottom: 3px;
    }

    .form-control {
        padding: 10px;
    }

    .cta-button {
        font-size: 0.9rem;
        padding: 10px 15px;
    }
}

@media (max-width: 480px) {
    .contact {
        padding: 20px 5px;
        margin: 15px 0;
    }

    .contact h3 {
        font-size: 1.2rem;
        margin-bottom: 15px;
    }

    .contact-form {
        padding: 15px;
        margin: 0 5px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .form-group label {
        margin-bottom: 2px;
    }

    .form-control {
        padding: 8px;
    }

    .cta-button {
        font-size: 0.8rem;
        padding: 8px 12px;
    }
}