/* General styling for the About Us section */
.about-us {
    padding: 60px 10px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 40px 0;
  }
  
  .about-us .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-us h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    color: #007BFF;
    margin-bottom: 20px;
  }
  
  .about-us p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
    font-family: 'Lato', sans-serif;
  }
  
  /* Responsive styling */
@media (max-width: 768px) {
    .about-us {
      padding: 30px 10px;
    }
  
    .about-us h2 {
      font-size: 1.8rem;
      margin-bottom: 15px;
    }
  
    .about-us p {
      font-size: 0.9rem;
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .about-us {
      padding: 20px 10px;
    }
  
    .about-us h2 {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
  
    .about-us p {
      font-size: 0.85rem;
      margin-bottom: 10px;
    }
  }